import React from 'react';

import bottleSpecsWhite from './../assets/images/specs/desktop/bottle-specs-whiteX2.png'
import bottleSpecsGrey from './../assets/images/specs/desktop/bottle-specs-greyX2.png'
import bottleSpecsSpaceGrey from './../assets/images/specs/desktop/bottle-specs-space-greyX2.png'
import bottleSpecsBlack from './../assets/images/specs/desktop/bottle-specs-blackX2.png'

import bottleSpecsGreyMobile from './../assets/images/specs/mobile/bottle-specs-grey-mobileX2.png';
import bottleSpecsWhiteMobile from './../assets/images/specs/mobile/bottle-specs-white-mobileX2.png';
import bottleSpecsSpaceGreyMobile from './../assets/images/specs/mobile/bottle-specs-space-grey-mobileX2.png';
import bottleSpecsBlackMobile from './../assets/images/specs/mobile/bottle-specs-black-mobileX2.png';

const Specs = (props) => {

  const getDesktopThermo = (color) => {
    switch (color) {
      case "white":
        return bottleSpecsWhite;
      case "light-grey":
        return bottleSpecsGrey;
      case "space-grey":
        return bottleSpecsSpaceGrey;
      case "black":
        return bottleSpecsBlack;
      default:
        return bottleSpecsWhite;
    }
  }

  const getMobileThermo = (color) => {
    switch (color) {
      case "white":
        return bottleSpecsWhiteMobile;
      case "light-grey":
        return bottleSpecsGreyMobile;
      case "space-grey":
        return bottleSpecsSpaceGreyMobile;
      case "black":
        return bottleSpecsBlackMobile;
      default:
        return bottleSpecsWhiteMobile;
    }
  }

  return (
    <section id="specs" className="container-fluid px-0">
      <div id="specs-desktop" className="d-none d-lg-block alabs-bg-dark-grey">
        <div className="container-fluid alabs-bg-dark-grey empty-spacer-top "></div>
        <div className="container-fluid text-center display-4 alabs-bg-dark-grey alabs-white pt-5">
          Technical specifications
        </div>
        <img loading="lazy" id="specs-img" className="pt-0 mt-0 img-fluid mx-auto d-block" src={getDesktopThermo(props.color)} alt="" />
      </div>

      <div id="specs-mobile" className="d-lg-none alabs-bg-dark-grey vh-100 d-flex flex-column justify-content-around">
        <div className="container-fluid text-center display-4 alabs-white pt-3 specs-z-index-top">
          Technical specifications
        </div>
        <div className="container-fluid d-flex flex-column justify-content-center align-items-center flex-wrap">
          <img loading="lazy" id="specs-img" className="pt-0 mt-0 img-fluid mx-auto d-block" src={getMobileThermo(props.color)} alt="" />
        </div>
      </div>
    </section>
  );
}

export default Specs;


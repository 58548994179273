import React from 'react';

import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"

import pabloPiccolotto from './../assets/images/team/pablo-piccolotto.jpg';
import federicoBrun from './../assets/images/team/federico-brun.jpg';
import leandroGualda from './../assets/images/team/leandro-gualda.jpg';
import lauraChalliol from './../assets/images/team/laura-challiol.jpg';
import fernandoDelarosa from './../assets/images/team/fernando-delarosa.jpg';

const Team = () => {

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
    loop: true
  })

  return (
    <section id="team" className="d-flex flex-wrap">
      <div className="container-fluid alabs-bg-white empty-spacer-top d-none d-lg-block"></div>
      <div className="container-fluid text-center display-4 alabs-bg-white alabs-dark-grey align-self-center">Team</div>
      <div id="team-desktop" className="d-none d-lg-flex flex-wrap container justify-content-evenly align-content-center my-5">
        <div className="col-lg-4 d-flex flex-column align-items-center">
          <img loading="lazy" className="img-fluid d-block" src={pabloPiccolotto} alt="" />
          <p className="m-0 pt-2 font-weight-light">Pablo</p>
          <p className="m-0 font-weight-bold">Piccolotto</p>
          <p className="m-0 font-italic">Software Engineer</p>
        </div>

        <div className="col-lg-4 d-flex flex-column align-items-center">
          <img loading="lazy" className="img-fluid d-block" src={federicoBrun} alt="" />
          <p className="m-0 pt-2 font-weight-light">Federico</p>
          <p className="m-0 font-weight-bold">Brun</p>
          <p className="m-0 font-italic">Software Engineer</p>
        </div>

        <div className="col-lg-4 d-flex flex-column align-items-center">
          <img loading="lazy" className="img-fluid d-block" src={leandroGualda} alt="" />
          <p className="m-0 pt-2 font-weight-light">Leandro</p>
          <p className="m-0 font-weight-bold">Gualda</p>
          <p className="m-0 font-italic">Industrial Designer</p>
        </div>

        <div className="col-lg-4 d-flex flex-column align-items-center ms-6">
          <img loading="lazy" className="img-fluid ml-auto mr-4 d-block" src={lauraChalliol} alt="" />
          <p className="m-0 pt-2 font-weight-light">Laura</p>
          <p className="m-0 font-weight-bold">Challiol</p>
          <p className="m-0 font-italic">Graphic Designer</p>
        </div>

        <div className="col-lg-4 d-flex flex-column align-items-center me-6">
          <img loading="lazy" className="img-fluid mr-auto ml-4 d-block" src={fernandoDelarosa} alt="" />
          <p className="m-0 pt-2 font-weight-light">Fernando</p>
          <p className="m-0 font-weight-bold">De La Rosa</p>
          <p className="m-0 font-italic">Aeronautical Engineer</p>
        </div>
      </div>
      <div id="team-mobile" className="w-100">
        <div className="navigation-wrapper">
          <div ref={sliderRef} className="keen-slider mb-4">
            <div className="keen-slider__slide d-flex justify-content-center flex-column text-center">
              <img loading="lazy" className="img-fluid mb-3 mx-auto" src={pabloPiccolotto} alt="" />
              <p className="m-0 pt-2 font-weight-light">Pablo</p>
              <p className="m-0 font-weight-bold"> <strong>Piccolotto</strong> </p>
              <p className="m-0 font-italic">Software Engineer</p>
            </div>
            <div className="keen-slider__slide d-flex justify-content-center flex-column text-center">
              <img loading="lazy" className="img-fluid mb-3 mx-auto" src={federicoBrun} alt="" />
              <p className="m-0 pt-2 font-weight-light">Federico</p>
              <p className="m-0 font-weight-bold">Brun</p>
              <p className="m-0 font-italic">Software Engineer</p>
            </div>
            <div className="keen-slider__slide d-flex justify-content-center flex-column text-center">
              <img loading="lazy" className="img-fluid mb-3 mx-auto" src={leandroGualda} alt="" />
              <p className="m-0 pt-2 font-weight-light">Leandro</p>
              <p className="m-0 font-weight-bold">Gualda</p>
              <p className="m-0 font-italic">Industrial Designer</p>
            </div>
            <div className="keen-slider__slide d-flex justify-content-center flex-column text-center">
              <img loading="lazy" className="img-fluid mb-3 mx-auto" src={lauraChalliol} alt="" />
              <p className="m-0 pt-2 font-weight-light">Laura</p>
              <p className="m-0 font-weight-bold">Challiol</p>
              <p className="m-0 font-italic">Graphic Designer</p>
            </div>
            <div className="keen-slider__slide d-flex justify-content-center flex-column text-center">
              <img loading="lazy" className="img-fluid mb-3 mx-auto" src={fernandoDelarosa} alt="" />
              <p className="m-0 pt-2 font-weight-light">Fernando</p>
              <p className="m-0 font-weight-bold">De La Rosa</p>
              <p className="m-0 font-italic">Aeronautical Engineer</p>
            </div>
          </div>
        </div>
        {slider && (
          <div className="dots">
            {[...Array(slider.details().size).keys()].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    slider.moveToSlideRelative(idx)
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                />
              )
            })}
          </div>
        )}
      </div>
    </section>
  )
}

export default Team;
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Logo from '../assets/images/header/isologotipo-white.svg'

const Sidebar = props => {
    let drawerClasses = 'side-drawer';
    const INSTAGRAM_URL = 'https://www.instagram.com/aconcagua.labs';
    const FACEBOOK_URL = 'https://www.facebook.com/AconcaguaLabs-110720044630851';
    const MAIL = 'contact@aconcagualabs.com';

    if (props.show) {
        drawerClasses = 'side-drawer open';
    }

    return (
        <nav className={drawerClasses}>
            <div className="drawer-container pt-2 h-100 d-flex flex-column">
                <div className="w-100 mt-4">
                    <img className="logo img-fluid align-self-start pt-3" src={Logo} alt="Aconcagua Labs logo" />
                </div>
                <div className="w-100 links mt-5">
                    <ul className="list-unstyled">
                        <li><a aria-current="page" href="#specs" onClick={props.handleDrawerToggleClick}>Specs</a></li>
                        <li><a href="#app" onClick={props.handleDrawerToggleClick}>App</a></li>
                        <li><a href="#in-action" onClick={props.handleDrawerToggleClick}>In action</a></li>
                        <li><a href="#testimonials" onClick={props.handleDrawerToggleClick}>Testimonials</a></li>
                        <li><a href="#team" onClick={props.handleDrawerToggleClick}>Team</a></li>
                    </ul>
                </div>
                <div className="footer pb-5 mt-auto">
                    <div id="social-media" className="ms-lg-auto align-items-center pb-3 d-flex align-items-center">
                        <a href={FACEBOOK_URL} className="fbLogo" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} className="fa-2x" />
                        </a>
                        <a href={INSTAGRAM_URL} className="igLogo" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} className="fa-2x" />
                        </a>
                        <a className="mailLogo" href={`mailto: ${MAIL}`}>
                            <FontAwesomeIcon icon={faEnvelope} className="fa-2x" />
                        </a>
                    </div>
                    <p>
                        &copy; 2021 aconcagualabs. <br /> All rights reserved.
                    </p>
                </div>
            </div>
        </nav>
    )
}

export default Sidebar;
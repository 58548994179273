import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import toast from 'react-hot-toast';

import ShareButtons from './ShareButtons';

const ShareOffCanvas = props => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(window.location.origin)
            .then(() => {
                toast.success('Link copied to clipboard', {
                    position: 'bottom-center'
                });
                props.setOffCanvasShow(false);
            });
    }

    return (
        <Offcanvas {...props}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Share</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ShareButtons handleCopyAction={copyToClipboard} handleActionClick={() => props.setOffCanvasShow(false)}></ShareButtons>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default ShareOffCanvas;
import React from 'react';
import mask from './../assets/images/testimonials/mask2.svg';
import testimonial1 from './../assets/images/testimonials/testimonials1x2.png';
import testimonial1Mobile from './../assets/images/testimonials/mobile.jpg';


const Testimonials = () => {
  return (
    <section className="testimonials-container alabs-bg-light-grey p-1">
      <div className="container-fluid py-1 h-100">
        <img className="testimonials-mask" src={mask} alt="" />
      </div>

      <div id="testimonials" className="container-fluid px-0">
        <div className="container-fluid empty-spacer-top"></div>
        <div className="container-fluid text-center display-4 alabs-dark-grey pt-5">
          Testimonials
        </div>
        <div id="testimonials-desktop" className="d-flex flex-grow-1 justify-content-center d-none d-lg-block">
          <img className="testimonials-image mx-auto d-flex" src={testimonial1} alt="" />
        </div>

        <div id="testimonials-mobile" className="d-flex flex-column flex-wrap px-5 d-block d-lg-none text-center mt-3 pb-5">
          <img className="img-fluid shadow mx-auto mb-3" src={testimonial1Mobile} alt="" />
          <h4 className="mb-2"><strong>Andrés Lacentra</strong></h4>
          <p className="text-center mx-auto">
            The Aconcagua Labs <strong>Smart Thermos</strong> allows me to enjoy my favorite beverage for much longer since it keeps its temperature exactly how I like it without losing heat between each serving. I highly recommend it to any Mate lover!
          </p>
        </div>
      </div>
    </section>
  )
}

export default Testimonials;
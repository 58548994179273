import React from 'react';

import firstImage from './../assets/images/in-action/one.jpg';
import secondImage from './../assets/images/in-action/two.jpg';
import thirdImage from './../assets/images/in-action/three.jpg';
import fourthImage from './../assets/images/in-action/four.JPG';

const InAction = () => {
  return (
    <section id="in-action" className="container-fluid px-0">
      <div className="d-lg-block container-fluid alabs-bg-white empty-spacer-top"></div>
      <div className="container-fluid text-center display-4 alabs-bg-white alabs-dark-grey pt-5">In Action</div>
      <div id="in-action-desktop" className="row d-flex justify-content-center h-75 px-0 py-4">
        <div className="d-flex w-75 mb-2">
          <div className="col-12 d-flex p-0">
            <div className="col-12 col-lg-3 one"></div>
            <div className="col-lg-9 row ms-4 d-flex p-0">
              <div className="col-12 two px-2"></div>
              <div className="col-12 col-lg three me-2 mt-4"></div>
              <div className="col-12 col-lg four ms-3 mt-4"></div>
            </div>
          </div>
        </div>
      </div>
      <div id="in-action-mobile" className="py-4 mt-4 container">
        <div id="first-row" className="row">
          <div id="left-side" className="col-6">
            <img loading="lazy" className="img-fluid" src={firstImage} alt="" />
          </div>
          <div id="right-side" className="col-6">
            <img loading="lazy" id="top-image" className="img-fluid right-img" src={fourthImage} alt=""/>
            <img loading="lazy" id="bottom-image" className="img-fluid right-img" src={thirdImage} alt="" />
          </div>
        </div>
        <div className="row">
          <img loading="lazy" className="img-fluid bottom-image" src={secondImage} alt="" />
        </div>
      </div>
    </section>
  )
}

export default InAction;
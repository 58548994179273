import React, { useEffect, useState } from 'react';
import './App.scss';
import Header from './components/Header';
import Home from './components/Home';
import Specs from './components/Specs';
import MobileApp from './components/MobileApp';
import InAction from './components/InAction';
import Testimonials from './components/Testimonials';
import Team from './components/Team';
import ContactUs from './components/contact-us/ContactUs';
import Footer from './components/Footer';
import Sidebar from "./components/Sidebar";
import Backdrop from './components/Backdrop';
import ShareModal from './components/share/ShareModal';
import ShareOffCanvas from './components/share/ShareOffCanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { Toaster } from 'react-hot-toast';

function App() {
  const [midnightClass, setMidnightClass] = useState('bg-transparent');
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [offCanvasShow, setOffCanvasShow] = useState(false);
  const [color, setColor] = useState('light-grey');

  const handleDrawerToggleClick = (e) => {
    setSideDrawerOpen((prevDrawerState) => !prevDrawerState);
  }

  const handleBackdropClick = () => {
    setSideDrawerOpen(false);
  }

  let backdrop;
  if (sideDrawerOpen) {
    backdrop = <Backdrop click={handleBackdropClick} />;
  }


  useEffect(() => {
    function handleScroll(e) {
      if (window.scrollY > 85 && window.scrollY < (window.outerHeight * 5)) {
        setMidnightClass('bg-blur')
      } else {
        setMidnightClass('bg-transparent');;
      }
    }
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [midnightClass]);

  const scrollIntoViewCallback = (target) => {
    const element = document.getElementById(target);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  const mobileShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Aconcagua Labs',
        text: `World's first Smart Thermos`
      });
    } else {
      setOffCanvasShow(true);
    }
  }

  return (
    <div className="App">
      <Toaster />
      <Sidebar show={sideDrawerOpen} handleDrawerToggleClick={handleDrawerToggleClick}></Sidebar>
      <ShareModal setModalShow={setModalShow} show={modalShow} onHide={() => setModalShow(false)} ></ShareModal>
      <ShareOffCanvas setOffCanvasShow={setOffCanvasShow} show={offCanvasShow} onHide={() => setOffCanvasShow(false)} placement={'bottom'}></ShareOffCanvas>
      {backdrop}
      <div>
        <button className="share-toggler btn btn-m ms-4 alabs-button-black alabs-white d-none d-lg-block" onClick={() => setModalShow(true)}>Share</button>
        <button className="mobile-header-button drawer-button d-lg-none" onClick={handleDrawerToggleClick}>
          <FontAwesomeIcon icon={faBars} className="fa-lg" aria-hidden />
        </button>
        <div id="header-section" className={"header fixed-top " + midnightClass}>
          <Header handleDrawerToggleClick={handleDrawerToggleClick} scrollIntoViewCallback={scrollIntoViewCallback} />
        </div>
        <button className="mobile-header-button share-button-mobile d-lg-none d-flex align-items-center"
          onClick={mobileShare}>
          <FontAwesomeIcon icon={faShareAlt} className="fa-lg" />
        </button>
      </div>
      <div>
        <Home scrollIntoViewCallback={scrollIntoViewCallback} setThermoColor={setColor} />
      </div>
      <div data-midnight="scrolled-dark-grey">
        <Specs color={color} />
      </div>
      <div data-midnight="scrolled-white">
        <MobileApp />
      </div>
      <div data-midnight="scrolled-white">
        <InAction />
      </div>
      <div data-midnight="scrolled-light-grey">
        <Testimonials />
      </div>
      <div data-midnight="scrolled-white">
        <Team />
      </div>
      <div id="contactUsView" className="shared-background-img wv-100" data-midnight="scrolled-form">
        <ContactUs />
        <Footer />
      </div>
    </div >
  );
}

export default App;

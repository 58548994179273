import React, { useEffect, useState } from 'react';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
//Videos
import capacityVideo from './../assets/videos/mobile-app/Water-level.mp4';
import batteryVideo from './../assets/videos/mobile-app/battery.mp4';
import bluetoothVideo from './../assets/videos/mobile-app/bluetooth.mp4';
import temperatureVideo from './../assets/videos/mobile-app/temperature.mp4';
import favVideo from './../assets/videos/mobile-app/fav.mp4';
//Images
import bluetoothGrey from './../assets/images/mobile-app/bluetooth-grey.png';
import bluetoothBlue from './../assets/images/mobile-app/bluetooth-blue.png';
import batteryGrey from './../assets/images/mobile-app/battery-grey.png';
import batteryBlue from './../assets/images/mobile-app/battery-blue.png';
import temperatureGrey from './../assets/images/mobile-app/temperature-grey.png';
import temperatureBlue from './../assets/images/mobile-app/temperature-blue.png';
import capacityGrey from './../assets/images/mobile-app/capacity-grey.png';
import capacityBlue from './../assets/images/mobile-app/capacity-blue.png';
import favGrey from './../assets/images/mobile-app/fav-grey.png';
import favBlue from './../assets/images/mobile-app/fav-blue.png';
import appStore from './../assets/images/mobile-app/app-storesX2.png'

const MobileApp = () => {
  const [appVideo, setVideo] = useState(temperatureVideo);
  const [feature, setFeature] = useState("temperature");

  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    loop: true
  });

  useEffect(() => {
    let videoArray = [capacityVideo, batteryVideo, bluetoothVideo, temperatureVideo, favVideo];
    videoArray.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  function ArrowLeft(props) {
    return (
      <svg
        onClick={props.onClick}
        className={"arrow arrow--left"}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 574 1024"
      >
        <path d="M564 9q10 10 10 23t-10 23L82 512l482 457q10 10 10 23t-10 23q-10 9-24 9t-24-9L10 535Q0 525 0 512t10-23L516 9q10-9 24-9t24 9z" />
      </svg>
    )
  }

  function ArrowRight(props) {
    return (
      <svg
        onClick={props.onClick}
        className={"arrow arrow--right"}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 574 1024"
      >
        <path d="M10 9Q0 19 0 32t10 23l482 457L10 969Q0 979 0 992t10 23q10 9 24 9t24-9l506-480q10-10 10-23t-10-23L58 9Q48 0 34 0T10 9z" />
      </svg>
    )
  }

  return (
    <section id="app" className="container-fluid ps-0 pe-0">
      <div className="container-fluid alabs-bg-white empty-spacer-top"></div>
      <div className="container-fluid text-center display-4 alabs-bg-white pt-5 pb-3">App</div>
      <div id="app-desktop" className="d-none d-lg-block">
        <div className="container-fluid">
          <div id="img-and-feautures">
            <div className="row mh-100">
              <div className="col-6 phone-video">
                <video id={feature} className={(appVideo === bluetoothVideo ? "d-block" : "d-none") + " pb-5 pt-4"} src={bluetoothVideo}
                  playsInline loop autoPlay muted>
                  Your browser does not support the video tag.
                </video>

                <video id={feature} className={(appVideo === batteryVideo ? "" : "d-none") + " pb-5 pt-4"} src={batteryVideo}
                  playsInline loop autoPlay muted>
                  Your browser does not support the video tag.
                </video>

                <video id={feature} className={(appVideo === temperatureVideo ? "" : "d-none") + " pb-5 pt-4"} src={temperatureVideo}
                  playsInline loop autoPlay muted >
                  Your browser does not support the video tag.
                </video>

                <video id={feature} className={(appVideo === capacityVideo ? "" : "d-none") + " pb-5 pt-4"} src={capacityVideo}
                  playsInline loop autoPlay muted>
                  Your browser does not support the video tag.
                </video>

                <video id={feature} className={(appVideo === favVideo ? "" : "d-none") + " pb-5 pt-4"} src={favVideo}
                  playsInline loop autoPlay muted>
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start pl-lg-5 pt-3">
                <div className="mx-5">

                  <div className="d-flex my-3">
                    <div className="feauture-icon feauture-icon-active">
                      <img src={feature === "temperature" ? temperatureBlue : temperatureGrey}
                        onClick={() => { setFeature("temperature"); setVideo(temperatureVideo) }} alt="" />
                    </div>
                    <div className="ps-4">
                      <div className="">TEMPERATURE</div>
                      <div className="fw-lighter">
                        Adjust your beverage's<br />temperature accuretly.
                      </div>
                    </div>
                  </div>

                  <div className="d-flex my-3">
                    <div className="feauture-icon">
                      <img src={feature === "bluetooth" ? bluetoothBlue : bluetoothGrey}
                        onClick={() => { setFeature("bluetooth"); setVideo(bluetoothVideo) }} alt="" />
                    </div>
                    <div className="ps-4">
                      <div className="">CONECTIVITY</div>
                      <div className="fw-lighter">
                        Easily connect to any Bluetooth<br />enabled smartphone.
                      </div>
                    </div>
                  </div>

                  <div className="d-flex my-3">
                    <div className="feauture-icon">
                      <img src={feature === "battery" ? batteryBlue : batteryGrey}
                        onClick={() => { setFeature("battery"); setVideo(batteryVideo) }} alt="" />
                    </div>
                    <div className="ps-4">
                      <div className="">BATTERY</div>
                      <div className="fw-lighter">
                        Know exactly how much energy<br />is left at any giventime.
                      </div>
                    </div>
                  </div>

                  <div className="d-flex my-3">
                    <div className="feauture-icon">
                      <img src={feature === "capacity" ? capacityBlue : capacityGrey}
                        onClick={() => { setFeature("capacity"); setVideo(capacityVideo) }} alt="" />
                    </div>
                    <div className="ps-4">
                      <div className="">CAPACITY</div>
                      <div className=" fw-lighter">
                        Stop gessing how much of your<br />beverage is left: Check it in our app!
                      </div>
                    </div>
                  </div>

                  <div className="d-flex my-3">
                    <div className="feauture-icon">
                      <img src={feature === "fav" ? favBlue : favGrey}
                        onClick={() => { setFeature("fav"); setVideo(favVideo) }} alt="" />
                    </div>
                    <div className="ps-4">
                      <div className="">FAVORITES</div>
                      <div className="fw-lighter">
                        Save predefined temperatures of your<br />favorite beverages for quicker access.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="app-stores-img d-flex justify-content-center">
              <img loading="lazy" className="img-fluid" src={appStore} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div id="app-mobile">
        <div className="navigation-wrapper">
          <div ref={sliderRef} className="keen-slider">
            <div className="keen-slider__slide slide d-flex flex-wrap flex-column">
              <video className="py-3" src={bluetoothVideo}
                playsInline disableRemotePlayback loop muted autoPlay>
                Your browser does not support the video tag.
              </video>
              <img className="feature-logo" src={bluetoothBlue} alt="" />
              <h3 className="">CONECTIVITY</h3>
              <p className="fw-lighter text-center">
                Easily connect to any Bluetooth<br />enabled smartphone.
              </p>
            </div>
            <div className="keen-slider__slide slide d-flex flex-wrap flex-column">
              <video className="py-3" src={batteryVideo}
                playsInline disableRemotePlayback loop muted autoPlay>
                Your browser does not support the video tag.
              </video>
              <img className="feature-logo" src={batteryBlue} alt="" />
              <h3 className="">BATTERY</h3>
              <p className="fw-lighter text-center">
                Know exactly how much energy<br />is left at any giventime.
              </p>
            </div>
            <div className="keen-slider__slide slide d-flex flex-wrap flex-column">
              <video className="py-3" src={temperatureVideo}
                playsInline disableRemotePlayback loop muted autoPlay>
                Your browser does not support the video tag.
              </video>
              <img className="feature-logo" src={temperatureBlue} alt="" />
              <h3 className="">TEMPERATURE</h3>
              <p className="fw-lighter text-center">
                Adjust your beverage's<br />temperature accuretly.
              </p>
            </div>
            <div className="keen-slider__slide slide d-flex flex-wrap flex-column">
              <video className="py-3" src={capacityVideo}
                playsInline disableRemotePlayback loop muted autoPlay>
                Your browser does not support the video tag.
              </video>
              <img className="feature-logo" src={capacityBlue} alt="" />
              <h3 className="">CAPACITY</h3>
              <p className="fw-lighter text-center">
                Stop gessing how much of your<br />beverage is left: Check it in our app!
              </p>
            </div>
            <div className="keen-slider__slide slide d-flex flex-wrap flex-column">
              <video className="py-3" src={favVideo}
                playsInline disableRemotePlayback loop muted autoPlay>
                Your browser does not support the video tag.
              </video>
              <img className="feature-logo" src={favBlue} alt="" />
              <h3 className="">FAVORITES</h3>
              <p className="fw-lighter text-center">
                Save predefined temperatures of your<br />favorite beverages for quicker access.
              </p>
            </div>
          </div>
          {slider && (
            <>
              <ArrowLeft onClick={(e) => e.stopPropagation() || slider.prev()} />
              <ArrowRight onClick={(e) => e.stopPropagation() || slider.next()} />
            </>
          )}
        </div>
        <img loading="lazy" className="stores img-fluid mx-auto d-flex" src={appStore} alt="" />
      </div>
    </section>
  )
}

export default MobileApp;
import React from 'react';

const ContactUs = () => {
  return (
    <section id="form" className="form vh-100">
      <div className="container-fluid bg-transparent empty-spacer-top d-none d-md-block"></div>
      <div className="container-fluid bg-transparent empty-spacer-top d-none d-md-block"></div>
      <div className="container-fluid d-flex flex-column px-0 pt-5 align-content-center">
        <div className="col-lg-6 d-flex justify-content-center align-items-start px-0 py-3">
          <div id="form-text">
            <div id="form-title" className="display-4 alabs-white mb-3">
              Pre-Order
            </div>
            <p id="form-subtitle1" className="alabs-white mb-5">
              Leave your contact information and
              <br />preferences in the form below so that we can
              <br />reach out to you as soon as production starts.
            </p>

            <p id="form-subtitle2" className="d-none">
              Leave your contact information and
              <br />preferences in the form below so that we can
              <br />reach out to you as soon as production starts.
            </p>
            <div>
              <a href="https://forms.gle/mcot8S6vqPTxmi5M9" target="_blank" rel="noreferrer"
                className="btn btn-l d-flex align-items-center justify-content-center alabs-button-black alabs-white" >
                Pre-order
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactUs;
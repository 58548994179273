import React, { useState } from 'react';

import mask from './../assets/images/home/mask1.svg';
import bottleGrey from './../assets/images/home/bottle-grey.png';
import bottleWhite from './../assets/images/home/bottle-white.png';
import bottleSpaceGrey from './../assets/images/home/bottle-space-grey.png';
import bottleBlack from './../assets/images/home/bottle-black.png';
import timelapse from './../assets/videos/home/aconcagua-timelapse.mp4';
import thumbnail from './../assets/videos/home/tumbnail.jpg'

const Home = (props) => {
  let heroVideo = null;

  const [bottleColor, setBottle] = useState(bottleGrey);
  const [actualColor, setColor] = useState("light-grey");
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const setHeroVideoRef = e => {
    heroVideo = e;
  }

  const playVideo = () => {
    heroVideo.play()
  }

  const getActiveClass = (color) => {
    return actualColor === color ? "color-option-active" : "";
  }

  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  new Image().src = thumbnail;

  return (
    <React.Fragment>
      <section id="home" className="container-fluid px-0">
        <div id="home-desktop" className="vh-100">
          {/* Image */}
          <img id="mobile-thermo-img" src={bottleColor} alt="" />
          <div className="overflow-hidden">
            <img loading="eager" id="hero-bottle-img" className="img-fluid mx-auto d-none d-lg-block hero-bottle"
              src={bottleColor} alt="" />
          </div>

          <div className="container-fluid vh-100 p-0 m-0">
            <div className="vh-100 d-flex flex-lg-row-reverse flex-column">
              <div id="video-container" className="col-lg-6 ps-0 pe-0 overflow-hidden">
                <img id="hero-mask" className="d-none d-lg-block hero-mask" src={mask}
                  alt="" />
                <div className="overflow-hidden">
                </div>
                <div style={{ position: 'relative', height: '100%' }}>
                  <img
                    src={thumbnail}
                    className="video-thumb tiny"
                    alt="thumb"
                    style={{ opacity: isVideoLoaded ? 0 : 1 }} />
                  <video className="video" id="hero-video" onLoadedData={onLoadedData} style={{ opacity: isVideoLoaded ? 1 : 0 }}/* ref={setHeroVideoRef} onLoadedData={() => playVideo.bind(this)}  */ playsInline loop autoPlay muted>
                    <source src={timelapse} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>

              <div id="text-container" className="col-lg-6 d-flex flex-column justify-content-center align-items-center ps-0 pe-0">
                <div id="hero-message" className="d-flex flex-column justify-content-center">
                  {/* Smart Thermos text... */}
                  <div>
                    <div className="display-4 alabs-dark-grey">
                      World's first
                    </div>
                    <div className="display-4 alabs-dark-grey fw-bold">
                      Smart Thermos
                    </div>
                    <p id="hero-secondary-text" className="fs-4 alabs-dark-grey">
                      Enjoy your favorite beverages <br /> at your preferred temperature.
                      <br /> With precision, and for much longer.
                      <br /> Everything at your fingertips using our app.
                    </p>
                    <div id="colors" className="color-container d-flex d-lg-none justify-content-center mt-2 align-items-end">
                      <div id="color-option-light-grey"
                        className={"me-2 color-option alabs-bg-light-grey " + getActiveClass("light-grey")}
                        onClick={() => { setBottle(bottleGrey); setColor("light-grey"); props.setThermoColor("light-grey") }}>
                      </div>
                      <div id="color-option-white"
                        className={"me-2 color-option alabs-bg-white " + getActiveClass("white")}
                        onClick={() => { setBottle(bottleWhite); setColor("white"); props.setThermoColor("white") }}>
                      </div>
                      <div id="color-option-space-grey"
                        className={"me-2 color-option alabs-bg-dark-grey " + getActiveClass("space-grey")}
                        onClick={() => { setBottle(bottleSpaceGrey); setColor("space-grey"); props.setThermoColor("space-grey") }}>
                      </div>
                      <div id="color-option-black"
                        className={"color-option alabs-bg-black " + getActiveClass("black")}
                        onClick={() => { setBottle(bottleBlack); setColor("black"); props.setThermoColor("black") }}>
                      </div>
                    </div>
                  </div>
                  <div className="pre-order-section">
                    <button id="hero-pre-order-btn" className="btn btn-l alabs-button-blue alabs-white text-end d-flex align-items-center justify-content-end"
                      type="submit"
                      onClick={() => props.scrollIntoViewCallback('contactUsView')}>
                      Pre-Order
                    </button>
                    <div id="colors" className="color-container d-none d-lg-flex justify-content-end mt-2">
                      <button id="color-option-light-grey"
                        className={"me-2 color-option alabs-bg-light-grey " + getActiveClass("light-grey")}
                        onClick={() => { setBottle(bottleGrey); setColor("light-grey"); props.setThermoColor("light-grey"); }}>
                      </button>
                      <button id="color-option-white"
                        className={"me-2 color-option alabs-bg-white " + getActiveClass("white")}
                        onClick={() => { setBottle(bottleWhite); setColor("white"); props.setThermoColor("white") }}>
                      </button>
                      <button id="color-option-space-grey"
                        className={"me-2 color-option alabs-bg-dark-grey " + getActiveClass("space-grey")}
                        onClick={() => { setBottle(bottleSpaceGrey); setColor("space-grey"); props.setThermoColor("space-grey") }}>
                      </button>
                      <button id="color-option-black"
                        className={"color-option alabs-bg-black " + getActiveClass("black")}
                        onClick={() => { setBottle(bottleBlack); setColor("black"); props.setThermoColor("black") }}>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Home;
import React from 'react';
import logo from './../assets/images/header/isologotipo-white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';


const Footer = () => {
  const INSTAGRAM_URL = 'https://www.instagram.com/aconcagua.labs';
  const FACEBOOK_URL = 'https://www.facebook.com/AconcaguaLabs-110720044630851';
  const MAIL = 'contact@aconcagualabs.com'

  return (
    <footer id="footer" className="p-3 alabs-bg-dark-grey container-fluid"> {/* d-sm-none d-lg-block  */}
      <div className="px-5 d-flex flex-wrap align-items-center justify-content-center footer-container">
        <div id="footer-logo" className="d-flex mb-4 mt-4 mb-lg-0 mt-lg-0">
          <a href="#home">
            <img id="isologotipo-footer" src={logo} alt="" className="footer-icon" />
          </a>
        </div>
        <div id="rights" className="p-3 pl-5 alabs-white d-flex align-items-center footer-icon justify-content-center">
          <div className="d-flex">
            &copy; 2021 aconcagualabs. <br className="d-md-none" /> All rights reserved.
          </div>
        </div>
        <div id="social-media" className="d-flex ms-lg-auto align-items-center">
          <a id="fbLogo" href={FACEBOOK_URL} className="d-flex justify-content-center align-items-center" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a id="igLogo" href={INSTAGRAM_URL} className="d-flex justify-content-center align-items-center" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a id="mailLogo" href={`mailto: ${MAIL}`} className="d-flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>
    </footer>

  )
}

export default Footer;
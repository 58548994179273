import React from 'react';
import blackLogo from '../assets/images/header/isologotipo-black.svg';

const Header = () => {
  new Image().src = blackLogo;

  return (
    <React.Fragment>
      <header id="header-section">
        <nav id="desktop-navbar" className="d-flex navbar navbar-expand-lg navbar-light bg-transparent d-none d-lg-flex h-100">{/* d-none d-lg-flex  */}
          <div className="d-flex justify-content-evenly w-100 px-4">
            <a href="#home" className="m-2">
              <div id="isologotipo-header" className="h-100 w-100" alt="Aconcagua Labs logo"></div>
            </a>
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav mb-2 mb-lg-0 w-100 d-flex justify-content-evenly">
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="#specs">Specs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#app">App</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#in-action" tabIndex="-1">In action</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#testimonials" tabIndex="-1">Testimonials</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#team" tabIndex="-1">Team</a>
                </li>
              </ul>
              <div className="d-flex">
                {<button className="btn btn-m ms-4 alabs-button-black alabs-white invisible">
                  Share
                </button>}
                <a href="#form" className="d-flex justify-content-center btn btn-m ms-4 alabs-button-blue alabs-white align-items-center">
                  <span>Pre-Order</span>
                </a>
              </div>
            </div>
          </div>
        </nav>
        <nav id="mobile-navbar" className="d-lg-none d-flex justify-content-around align-items-center h-100">
          <div id="isologotipo-header" alt="Aconcagua Labs logo"></div>
        </nav>
      </header>
    </React.Fragment>
  )
}

export default Header;
import React from 'react';
import { faFacebookF, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ShareButtons = props => {
    const ShareText = `World's first Smart Thermos. Enjoy your favorite beverages at your preferred temperature. With precision, and for much longer. Everything at your fingertips using our app`;
    const TwitterShareURL = `https://twitter.com/intent/tweet?url=${window.location.origin}&text=${ShareText}`;
    const FbShareURL = `https://www.facebook.com/sharer.php?u=https://aconcagualabs.com`;
    const WppShareURL = `https://api.whatsapp.com/send?text=${ShareText}%20${window.location.origin}`
    const MailShareURL = `mailto:?subject=AconcaguaLabs&body=${window.location.origin} ${ShareText}`

    const actions = [
        {
            text: 'Whatsapp',
            icon: faWhatsapp,
            class: 'wpp',
            link: WppShareURL
        },
        {
            text: 'Facebook',
            icon: faFacebookF,
            class: 'fb',
            link: FbShareURL
        },
        {
            text: 'Twitter',
            icon: faTwitter,
            class: 'twitter',
            link: TwitterShareURL
        },
        {
            text: 'Mail',
            icon: faEnvelope,
            class: 'mail',
            link: MailShareURL
        }
    ]

    return (
        <div className="share-buttons-container">
            <button className="share-button p-2 link"
                onClick={props.handleCopyAction}>
                <span className="circle link">
                    <FontAwesomeIcon className="mb-1 fa-2x" icon={faCopy} />
                </span>
                <span className="share-text">
                    Copy Link
                </span>
            </button>
            {
                actions.map(action =>
                    <a className="share-button p-2" onClick={props.handleActionClick} href={action.link} target='_blank' rel='noreferrer'>
                        <span className={'circle ' + action.class}>
                            <FontAwesomeIcon className="fa-2x" icon={action.icon} />
                        </span>
                        <span className="share-text">
                            {action.text}
                        </span>
                    </a>
                )
            }
        </div>
    )
}

export default ShareButtons;
import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import toast from 'react-hot-toast';
import ShareButtons from './ShareButtons';

const ShareModal = props => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(window.location.origin)
            .then(() => {
                toast.success('Link copied to clipboard', {
                    position: 'bottom-center'
                });
                props.setModalShow(false);
            });
    }

    return (
        <Modal {...props} size="lg" centered scrollable={true}>
            <ModalBody>
                <h4>Share</h4>
                <ShareButtons handleCopyAction={copyToClipboard}></ShareButtons>
            </ModalBody>
        </Modal>
    )
}

export default ShareModal;